import React from 'react';
import { ResourcesComponent} from './Resources.Component';
import { httpServices } from '../../../services/httpServices';
import { ErrorPage } from '../../common/error';
import { CustomProgressBar } from '../../common/CustomProgressBar';

const formFields = {
    'id' : 0,
    'lbCategoryName':null,
    'resourceName' : null,
    'contractVehicle' : null,
    'gsaLaborCategory': null,
    'start_date': null,
    'end_date': null,
};

const filterFields = {
    'employmentStatus' : 1,
    'laborCategory' : 0,
    'contractVehicle' : 0,
    'gsaLaborCategory' :0,
    'resourceName' :0,
};
const formValues = {
    'start_date' : null,
    'end_date' : null,
    'CTC_rate' : null,
    'type' :0,
};
export class ResourcesContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            permission : false,
            projectResources : [],
            project :[],
            contractVehicles : [],
            formFields : formFields,
            open : false,
            confirmDelete : false,
            errors :{},
            users : [],
            lcat : [],
            snackbar : false,
            id : 0,
            gsaLcats : [],
            isEditable : false,
            openRates : false,
            resourceRates : [],
            formValues : formValues,
            otherPermissions : [],
            employeeStatus : [],
            showLoader : true,
            dataLoaded : false,
            deletePermission : false,
            editPermission : false,
            filterViewState : false,
            filterFields : filterFields,
            assinedResourceList : [],
            buttonLoader : false,
            projectResourceId : 0,
            result : null,
            message : null,
            rateMessage : null,
            resourceStartDate : null,
            resourceEndDate : null,
            resourceInfo : [],
            prevEndDate : '',
            nameFormat : 'FIRSTLAST'
        };
        this.onAdd = this.onAdd.bind(this);
        this.onClose = this.onClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.snackbarClose = this.snackbarClose.bind(this);
        this.onUpdateLcrRate = this.onUpdateLcrRate.bind(this);
        this.filterView = this.filterView.bind(this);
        this.handleFilterOnChange = this.handleFilterOnChange.bind(this);
        this.handleFilterOnSubmit = this.handleFilterOnSubmit.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
        this.handleResourceConfirm = this.handleResourceConfirm.bind(this);
        this.deleteResource = this.deleteResource.bind(this);
    }

    componentDidMount()
    {
        this.getListProjectResources(this.props.ProjectId);
    }

    componentWillUnmount () {
        for (const flkey in filterFields) {
            if (flkey === 'employmentStatus') {
                filterFields[flkey] = 1;
            } else {
                filterFields[flkey] = 0;
            }
        }
    }

    snackbarClose = () => {
        this.setState({
            snackbar : false,
        });
    }

    resetFilter = () => {
        let f = {
             employmentStatus : '1',
             laborCategory : '0',
             contractVehicle : '0',
             gsaLaborCategory :'0',
             resourceName :'0',
        };
        this.setState({
            filterFields: f,
            filterViewState : false
        },() => {this.getListProjectResources(this.props.ProjectId);});
    }


    handleFilterOnSubmit =() =>{
        this.setState({filterViewState : false});
        this.getListProjectResources(this.props.ProjectId);
    }
    handleFilterOnChange = (evt, value) => {
        const f = this.state.filterFields;
        const name = evt.target.name;
        if (name === 'contractVehicle') {
            this.getGsaLaborCategories(evt.target.value);
        }
        f[name] = evt.target.value;
        this.setState({
            filterFields: f
        });
    }

    filterView = (anchor, open) => (event) => {
        this.setState({filterViewState : open});
    };

    getListProjectResources = (projectId, id) => {
        let apiEndpoint = 'api/project/listProjectResources';
        let employmentStatus = this.state.filterFields.employmentStatus;
        let resourceName = this.state.filterFields.resourceName;
        let laborCategory = this.state.filterFields.laborCategory;
        let gsaLaborCategory = this.state.filterFields.gsaLaborCategory;
        let contractVehicle = this.state.filterFields.contractVehicle;
        return httpServices.post(apiEndpoint, {'project_id' : projectId, 'resource_id' : id, 'employmentStatus': employmentStatus, 'resourceName':resourceName, 'laborCategory':laborCategory, 'gsaLaborCategory':gsaLaborCategory, 'contractVehicle':contractVehicle })
            .then(response => {
                if (response.data.permission) {
                    if (id) {
                        const f = this.state.formFields;
                        f['lbCategoryName'] = response.data.resource_info.project_lcat_id;
                        f['resourceName'] = response.data.resource_info.resource_name;
                        f['start_date'] = response.data.resource_info.start_date;
                        f['end_date'] = response.data.resource_info.end_date;
                        f['contractVehicle'] = response.data.resource_info.contract_vehicle_id;
                        f['gsaLaborCategory'] = response.data.resource_info.gsa_lcat_id;
                        this.setState({formFields : f,
                            gsaLcats : response.data.gsa_lcats,
                            employeeStatus : response.data.employee_status,
                            otherPermissions : response.data.otherPermissions,
                            deletePermission : response.data.delete_permission,
                            editPermission : response.data.edit_permission,
                            showLoader : false,
                            dataLoaded : true,
                            prevEndDate : response.data.resource_info.end_date,
                        });
                    }
                    else {
                        this.setState({
                            projectResources : response.data.project_resources,
                            contractVehicles : response.data.contract_vehicles,
                            project : response.data.project,
                            users : response.data.users,
                            assinedResourceList : response.data.assinedResourceList,
                            lcat : response.data.lcat,
                            showLoader : false,
                            permission : response.data.permission,
                            otherPermissions : response.data.otherPermissions,
                            editPermission : response.data.edit_permission,
                            deletePermission : response.data.delete_permission,
                            employeeStatus : response.data.employee_status,
                            dataLoaded : true
                            });
                    }
                }
                else {
                    this.setState({
                        showLoader : false,
                        dataLoaded : true,
                    });
                }
            })
             .catch(function(error) {
                console.log("Error: "+error);
            });
    }

    onAdd = () => {
        let startDate = this.state.project.project_sdate;
        let fields = this.state.formFields;
        fields['start_date'] = startDate;
        this.setState({
            open : true,
            isEditable : false,
            formFields : fields,
            id : 0,
        });
    };

     onClose = () => {
        this.setState({
            open : false,
            errors : {},
            openRate : false,
            showLoader : false,
            dataLoaded : true,
            confirmDelete : false
        });
        this.clearForm();
    };

    clearForm = () => {
        let ff = this.state.formFields;
        for (let key in ff) {
            if(key === 'id') {
                ff[key] = 0;
            }
            else {
                ff[key] = null;
            }
        }
        this.setState({
            formFields: ff
        });
    };

    handleOnChange = (evt, value) => {
        const field = evt.target.name;
        const ff = this.state.formFields;
        if (field === 'contractVehicle') {
            this.getGsaLaborCategories(evt.target.value);
        }
        ff[field] = evt.target.value;
        this.setState({
            formFields : ff
        });
    };

     getGsaLaborCategories = (contractVehicleId) => {
        let apiEndpoint = 'api/project/getGsaLcat';
        return httpServices.post(apiEndpoint, {'contractVehicleId' : contractVehicleId})
            .then(response => {
                if (response.data) {
                    this.setState({
                            gsaLcats : response.data.gsa_lcats,
                        });
                }
                })
             .catch(function(error) {
                console.log("Error: "+error);
            });
    }

    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.formFields.start_date) {
            errors["start_date"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.resourceName) {
            errors["resourceName"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.lbCategoryName &&  this.state.project.labor_category === 1  ) {
            errors["lbCategoryName"] = "Cannot be empty";
            formIsValid = false;
        }
        this.setState({errors : errors});
        return formIsValid;
    }

    handleRateSubmit = (action, rateData) => {
        let data ={};
        data.action = action;
        data.project_resource_id  = this.state.projectResourceId;
        let dateStart = null;
        let dateEnd = null;
        if (action === 'update' || action === 'delete') {
            dateStart = new Date(rateData.start_date);
            dateEnd = (rateData.end_date) ? new Date(rateData.end_date) : null;
            // new Date(rateData.end_date);
        }
        else {
            dateStart = rateData.start_date;
            dateEnd = (rateData.end_date) ? rateData.end_date : null;
        }
        if (rateData.id) {
            data.id = rateData.id;
        }
        let  startDate = dateStart.getFullYear() + '-' + (dateStart.getMonth() + 1) + '-' + dateStart.getDate();
        data.start_date =  startDate;
        if (dateEnd !== null && dateEnd !== '') {
            let  endDate = dateEnd.getFullYear() + '-' + (dateEnd.getMonth() + 1) + '-' + dateEnd.getDate();
            data.end_date = endDate;
        }
        else {
            data.end_date = null;
        }
        data.type = rateData.type;
        data.CTC_rate = rateData.CTC_rate;
        let apiEndpoint = 'api/project/manageLcrRate';
        return httpServices.post(apiEndpoint, data)
            .then(response => {
                if (response.data.permission) {
                    let msg = '';
                    if (!response.data.errors) {
                        if (response.data.action === 'add') {
                            if (response.data.flag === true) {
                                if (response.data.dateValid === false) {
                                    msg = "Rate dates lie outside service period";
                                    this.setState({ result: false });
                                }
                                else if (response.data.isValid === true) {
                                    msg = "Rate added scuccessfully";
                                    this.setState({ result: true });
                                }
                                else {
                                    msg = "Rate already exists for the selected period.";
                                    this.setState({ result: false });
                                }
                            }
                            else {
                                msg = "Start date cannot exceed end date";
                                this.setState({ result: false });
                            }
                        }
                        if (response.data.action === 'update') {
                            if (response.data.flag === true) {
                                if (response.data.dateValid === false) {
                                    msg = "Rate dates lies outside service period";
                                    this.setState({ result: false });
                                }
                                else if (response.data.isValid === true) {
                                    msg = "Rate details updated scuccessfully";
                                    this.setState({ result: true });
                                }
                                else {
                                    msg = "Rate already exists for the selected period.";
                                    this.setState({ result: false });
                                }
                            }
                            else {
                                msg = "Start date cannot exceed end date";
                                this.setState({ result: false });
                            }
                        }
                    } else {
                        msg = 'Invalid number at rate field'
                        this.setState({ result: false });
                    }
                    if (response.data.action === 'delete') {
                        if (response.data.isValid === true) {
                            msg = "Rate details deleted scuccessfully";
                            this.setState({ result: true });
                        }
                    }
                    this.setState({
                        formValues: response.data.updated_resource_rate,
                        rateMessage: msg
                    });
                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    }

    handleResourceConfirm = (id, proceed = null)=> {
        if (this.handleValidation()) {
            if(this.state.prevEndDate !== this.state.formFields.end_date) {
                this.setState({resourceConfirm : true});
                if(proceed !== null){
                    if(this.state.formFields.end_date !== null && this.state.formFields.end_date !== '') {
                        this.setState({resourceConfirm : false});
                        let apiEndpoint = 'api/project/validateWithProjectLcatDetails';
                        return httpServices.post(apiEndpoint, {'id' : id, 'end_date':this.state.formFields.end_date})
                            .then(response => {
                                if(proceed === "No") {
                                    if (response.data.flag) {
                                        let lcat_name = response.data.lcatName;
                                        let lcat_res_end_date = response.data.details[0].lcat_res_end_date;
                                        let staff_name = response.data.res_name;
                                        this.setState({
                                            snackbar : true,
                                            variant : 'error',
                                            message : 'Unable to save! End date of '+staff_name+' in labor category '+lcat_name+' is '+lcat_res_end_date+', which is beyond the given end date.'});
                                    }
                                    else {
                                        this.handleSubmit(id,false); //Updating resource end date only
                                    }
                                }
                                else {
                                    this.handleSubmit(id,true); //Updating both resource end date and project lcat resource end date
                                }
                            })
                    }
                    else {
                        this.setState({resourceConfirm : false});
                        if(proceed === "No") {
                            this.handleSubmit(id,false); //Only resource end date
                        }
                        else {
                            this.handleSubmit(id,true); //both
                        }
                    }
                }
            }
            else {
                this.handleSubmit(id);
            }
        }
    }   

    handleSubmit = (id, update=null) => {
         this.setState({buttonLoader : true });
         if(this.handleValidation()){
            let project_id = this.props.ProjectId;
            let data = this.state.formFields;
            data.project_id = project_id;
            if (id) {
                data.id = id;
            }
            data.update = update; 
            if (data) {
                let apiEndpoint = 'api/project/saveProjectResource';
                return httpServices.post(apiEndpoint,data)
                    .then(response => {
                        if (typeof response.data.valid === 'undefined') {
                            if (response.data.permission) {
                                if(response.data.error) {
                                    this.setState({open: true,
                                        snackbar : true,
                                        variant : 'error',
                                        message : response.data.msg,
                                        buttonLoader : false,
                                    });
                                }
                                else {
                                    this.setState({open: false,
                                        snackbar : true,
                                        variant : 'success',
                                        message : response.data.successMessage,
                                        buttonLoader : false,
                                        permission : response.data.permission,
                                        otherPermissions : response.data.otherPermissions,
                                        id :0,
                                    });
                                    this.clearForm();
                                    this.getListProjectResources(this.props.ProjectId);
                                }
                            }
                            else {
                                this.setState({
                                    buttonLoader : false
                                });
                            }
                        }
                        else {
                            this.setState({
                                buttonLoader: false,
                                errors: response.data.errors
                            });
                        }
                    })
                    .catch(function(error) {
                        console.log("Error: "+error);
                    });
            }
        } else {
            this.setState({ buttonLoader : false });
        }
    };

    onUpdate = (projectId, id) => {
        this.setState({
            open : true,
            isEditable : true,
            id : id,
        });
        this.getListProjectResources(projectId, id);
    }

    onUpdateLcrRate = (projectId, id) => {
        this.setState({projectResourceId : id});
        let apiEndpoint = 'api/project/listProjectResources';
        return httpServices.post(apiEndpoint, {'project_id' : projectId, 'resource_id' : id})
            .then(response => {
                if (response.data.permission) {
                    let resStart = response.data.resource_info.start_date.split("-");
                    let display_startDate = resStart[1]+'/'+resStart[2]+'/'+resStart[0];
                    let display_endDate = null;
                    if (response.data.resource_info.end_date !== null ) {
                        let resEnd = response.data.resource_info.end_date.split("-");
                        display_endDate = resEnd[1]+'/'+resEnd[2]+'/'+resEnd[0];
                    }
                    this.setState({openRate: true,
                    resourceRates: response.data.resource_rates,
                    formValues : response.data.resource_rates,
                    resourceStartDate : display_startDate,
                    resourceEndDate : display_endDate,
                    result : null,
                    rateMessage : null,
                    resourceInfo : response.data.resource_info,
                    });
                }
            })
    }

    deleteResource = (id, allocationDeleteConfirmed = null) => {
        let apiEndpoint = 'api/project/deleteResource';
        return httpServices.post(apiEndpoint, {'id' : id, 'allocationDeleteConfirmed' : allocationDeleteConfirmed})
            .then(response => {
                if (response.data.permission) {
                    if (response.data.error) {
                        if (response.data.allocation_exists) {
                            this.setState({
                                confirmDelete : true,
                                id : id,
                            });
                        }
                        else {
                            this.setState({
                                snackbar : true,
                                variant : 'error',
                                message : response.data.message,
                                confirmDelete: false,
                            });
                        }
                    }
                    else {
                        this.setState({
                            snackbar : true,
                            variant : 'success',
                            message : response.data.message,
                            confirmDelete: false,
                        });
                        this.getListProjectResources(this.props.ProjectId);
                    }
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    };

    render(){
        return(
            <>
             <CustomProgressBar
                    loaderOpen={this.state.showLoader}
                />
                {(this.state.dataLoaded) ?
                    <>
                    {(this.state.permission) ?
            <ResourcesComponent
            Project = {this.state.project}
            ProjectResources = {this.state.projectResources}
            ContractVehicles = {this.state.contractVehicles}
            OnAdd = {this.onAdd}
            Open = {this.state.open}
            OnClose = {this.onClose}
            HandleOnChange = {this.handleOnChange}
            HandleSubmit = {this.handleSubmit}
            Errors = {this.state.errors}
            FormFields = {this.state.formFields}
            OnUpdate = {this.onUpdate}
            DeleteResource = {this.deleteResource}
            Users = {this.state.users}
            AssinedResourceList = {this.state.assinedResourceList}
            Lcat = {this.state.lcat}
            Variant = {this.state.variant}
            Message = {this.state.message}
            Snackbar = {this.state.snackbar}
            SnackbarClose = {this.snackbarClose}
            Id = {this.state.id}
            ProjectId = {this.props.ProjectId}
            GsaLcats = {this.state.gsaLcats}
            IsEditable = {this.state.isEditable}
            OnUpdateLcrRate = {this.onUpdateLcrRate}
            OpenRate = {this.state.openRate}
            ResourceRates = {this.state.resourceRates}
            HandleOnRateChange = {this.handleOnRateChange}
            FormValues = {this.state.formValues}
            HandleRateSubmit = {this.handleRateSubmit}
            OtherPermissions = {this.state.otherPermissions}
            EmployeeStatus = {this.state.employeeStatus}
            DeletePermission = {this.state.deletePermission}
            EditPermission = {this.state.editPermission}
            FilterViewState = {this.state.filterViewState}
            FilterView = {this.filterView}        
            FilterFields = {this.state.filterFields}    
            HandleFilterOnSubmit = {this.handleFilterOnSubmit}
            HandleFilterOnChange ={this.handleFilterOnChange}
            ResetFilter = {this.resetFilter}
            ButtonLoader = {this.state.buttonLoader}
            ProjectResourceId = {this.state.projectResourceId}
            Result = {this.state.result}
            RateMessage = {this.state.rateMessage}
            ResourceStartDate = {this.state.resourceStartDate}
            ResourceEndDate = {this.state.resourceEndDate}
            ResourceInfo = {this.state.resourceInfo}
            ResourceConfirm = {this.state.resourceConfirm}
            HandleResourceConfirm = {this.handleResourceConfirm}
            NameFormat ={this.state.nameFormat}
            InheritResourceFlag = {this.props.InheritResourceFlag}
            ConfirmDelete = {this.state.confirmDelete}
                    />
              :
                        <ErrorPage/>
                    }
                    </>
                :<></>
            }
            </>
        )
    }
}
